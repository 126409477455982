<template>
  <router-view/>
</template>
<script>
export default {
  computed: {
    // 计算属性来获取需要缓存的页面
    cachedRoutes() {
      // 在这里列出需要缓存的路由名称
      return ['SendGoods'];
    }
  }
}
</script>

<style>
*{
  padding:0px;
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/*nav {*/
/*  padding: 30px;*/
/*}*/

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@media (min-width: 500px) {
  #app {
    margin: 0 auto;
    max-width: 500px;
  }
}
</style>
