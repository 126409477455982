import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/InspectionCenter'
  },
  {
    path: '/Inspectioncenter',
    name: 'InspectionCenter',
    component: () => import('../views/InspectionCenter.vue')
  },
  {
    path: '/shippingdetails',
    name: 'ShippingDetails',
    component: () => import('../views/ShippingDetails.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
